<template>
  <b-modal
    id="market-receive-modal"
    ref="market-receive-modal"
    hide-footer
    size="md"
    title="ตั้งค่ารับของ"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="กลุ่มหวย"
      >
        <b-form-input
          type="text"
          placeholder="กลุ่มหวย"
          disabled
          v-model="groupTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="หวย"
      >
        <b-form-input
          type="text"
          placeholder="หวย"
          disabled
          v-model="marketTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รับของ"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <table class="table-payrate table table-sm">
          <thead>
            <tr>
              <th width="50%">เลข</th>
              <th width="50%">ตั้งค่ารับของต่อตัว</th>
            </tr>
            <tr class="bg-light">
              <th></th>
              <th><b-form-input size="sm" v-model="inputAll" :number="true"></b-form-input></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bet in marketOpenBets" :key="bet.code">
              <td>
                {{ bet.text }}
              </td>
              <td>
                <b-input-group size="sm">
                  <b-form-input
                    size="sm"
                    v-model="input.receive[bet.code]"
                    :number="true"
                    required>
                  </b-form-input>
                </b-input-group>
              </td>
            </tr>
          </tbody>
        </table>
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import {marketOpenBets} from '@/configs/market.config'
import MarketSettingService from "@/services/MarketSettingService"
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

const defaultInput = {
  marketId: null,
  receive: {
    threeNumberTop: '',
    threeNumberTode: '',
    twoNumberTop: '',
    twoNumberBottom: '',
    runTop: '',
    runBottom: ''
  }
}

export default {
  name: 'MarketReceiveModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      input: JSON.parse(JSON.stringify(defaultInput)),
      inputAll: '',
      needReload: false,
      isProcess: false
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    market() {
      if(!this.data?.marketId)
        return null

      return this.$store.state.markets.find((m)=>{
        return m._id === this.data.marketId
      })
    },
    groupTitle() {
      if(!this.market)
        return ''

      const group = this.$store.state.marketGroups.find((g)=>{
        return g._id === this.market.groupId
      })

      return group?.groupTitle
    },
    marketTitle() {
      return this.market?.marketTitle
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      if(this.data) {
        const data = JSON.parse(JSON.stringify(this.data))
        this.input = data
      }
    },
    inputAll() {
      if(this.inputAll > 0) {
        this.marketOpenBets.forEach((bet)=>{
          this.input.receive[bet.code] = this.inputAll
        })
      }
    }
  },
  methods: {
    resetModal() {
      this.input = JSON.parse(JSON.stringify(defaultInput))
      this.inputAll = ''
      this.needReload = false
    },
    showModal() {
      this.$refs['market-receive-modal'].show()
    },
    hideModal() {
      this.$refs['market-receive-modal'].hide()
    },
    handleSubmit() {
      console.log(this.input)
      this.isProcess = true
      MarketSettingService.updateMarketSettingReceive({
        marketIds: [this.input.marketId],
        receive: this.input.receive
      })
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
